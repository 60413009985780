import { getToken } from "views/Utils/Common";

// https://apilealme.angelguardian.com.mx/
// https://itoo.mx/api
const baseUrl = "https://apilealme.angelguardian.com.mx/api";//process.env.REACT_APP_API_URL;

const fetchSinToken = ( endpoint, data, method = 'GET' ) => {

    const url = `${ baseUrl }/${ endpoint }`;

    if ( method === 'GET' ) {
        return fetch( url ).then(handleResponse);
    } else {
        return fetch( url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify( data )
        }).then(handleResponse);
    }
}

const fetchConToken = ( endpoint, data, method = 'GET', isFile = false ) => {

    const url = `${ baseUrl }/${ endpoint }`;
    const token = getToken(); // localStorage.getItem('token') || '';
    //console.log(token);

    if ( method === 'GET' ||  method === 'DELETE') {
        return fetch( url, {
            method,
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(handleResponse);
    } else {
        if(!isFile){

        return fetch( url, {
            method,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify( data )
        }).then(handleResponse);

    }else{
        return fetch( url, {
            method,
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: data
        }).then(handleResponse);

    //}
    }
    }
}


const fetchConTokenFile = ( endpoint, data, method = 'GET', isFile = true ) => {

    const url = `${ baseUrl }/${ endpoint }`;
    const token = getToken(); // localStorage.getItem('token') || '';
    //console.log(token);

    if(isFile){
        return fetch( url, {
            method,
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: data
        }).then(response=> {return response});
    }
    
}

// helper functions

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        /*
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }*/
        return data;
    });
}


export {
    fetchSinToken,
    fetchConToken,
    fetchConTokenFile
}